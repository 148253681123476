import { createContext, useContext, useEffect, useState } from "react";
import { navigateToUrl } from "single-spa";
import { local } from "./utils/storage";
import {
  ACCOUNT_TYPE_USER,
  BUSINESS_USER_TYPE,
  IAM_USER_TYPE,
  SSO_USER_TYPE,
} from "./constants/constants";
import { authClient, userClient } from "./grpc/client";
import { AccountType, clearToken, getToken, saveToken } from "@/ds-apis";
import Cookies from "js-cookie";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  // We can have our existing Auth logic here, just making sure that we have a single QueryClient.

  const [loggedIn, setLoggedIn] = useState(() =>
    JSON.parse(local.getItem("defense-station-loggedin"))
  );

  useEffect(() => {
    const loginListener = () => {
      setLoggedIn(true);
    };

    const addLogoutListener = () => {
      setLoggedIn(false);
    };

    // AuthManager.addLoginListener(loginListener);
    // AuthManager.addLogoutListener(addLogoutListener);

    // return () => {
    //   AuthManager.removeLoginListener(loginListener);
    //   AuthManager.removeLogoutListener(addLogoutListener);
    // };
  }, []);

  // Any user details we want can go here
  const value = {
    loggedIn,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export const useAuth = () => useContext(AuthContext);

export const redirectToLogin = (persistLastPage) => {
  console.log("Redirecting to login")
  if (local.getItem("redirectTo")) {
    return;
  }
  let redirectionURL = "/login";
  if (persistLastPage) {
    // redirectionURL = redirectionURL + "?redirectTo=" + window.location.href;
    if (window.location.pathname != "" && window.location.pathname != "/" && window.location.pathname != "/login") {
      local.setItem("redirectTo", window.location.href);
    }
  }
  window.location.href = redirectionURL;
};

const redirectToHome = async () => {
  let user = null;
  try {
    const data = await userClient.getAuthInfo({});
    user = data.user;
  } catch (e) {
    console.log(e);
  }
  if (!user) {
    removeState();
    clearToken();
    window.location.href = "/signin";
    return;
    // removeState();
    // removeToken();
    // window.location.href = "/login";
    // return;
  }
  // if (window.location.origin != process.env.MANAGEMENT_APP_ENDPOINT) {
  //   if (
  //     user?.user_type == IAM_USER_TYPE ||
  //     user?.user_type == BUSINESS_USER_TYPE
  //   ) {
  //     // navigateToUrl("/management-station");
  //     navigateToUrl("/polciy");
  //   } else if (user?.user_type == SSO_USER_TYPE) {
  //     navigateToUrl("/employee-station");
  //   }
  // } else {
  if (
    user?.accountType == AccountType.PERSONAL &&
    !window.location.origin.includes("ds-plus-app")
  ) {
    navigateToUrl("/sypher");
  } else {
    navigateToUrl("/policy");
  }
  // }
};

const InnerLogin = ({ children }) => {
  const loggedIn = isLoggedIn();
  if (loggedIn) {
    return children;
  } else {
    redirectToLogin(true);
    return null;
  }
};

export const LoggedInRoute = ({ children }) => (
  <AuthProvider>
    <InnerLogin>{children}</InnerLogin>
  </AuthProvider>
);

const InnerLogout = ({ children }) => {
  const loggedIn = isLoggedIn();
  if (loggedIn) {
    redirectToHome();
    return null;
  } else {
    return children;
  }
};

export const LoggedOutRoute = ({ children }) => (
  <AuthProvider>
    <InnerLogout>{children}</InnerLogout>
  </AuthProvider>
);

export const signIn = async (email, verify_token, captcha_code, otp = "") => {
  try {
    const res = await authClient.getAuthToken({
      email,
      deviceToken: verify_token,
      recaptchaCode: captcha_code,
      otp,
    });
    saveToken(res.authToken);
    return res;
  } catch (e) {
    throw e;
  }
};

export const signInWithToken = async (token) => {
  try {
    saveToken(token);
  } catch (e) {
    throw e;
  }
};

export const iamSignIn = async (
  account_id,
  username,
  verify_token,
  captcha_code,
  otp = ""
) => {
  try {
    const res = await authClient.getAuthToken({
      accountId: account_id,
      username: username,
      deviceToken: verify_token,
      recaptchaCode: captcha_code,
      otp,
    });
    saveToken(res.authToken);
    return res;
  } catch (e) {
    throw e;
  }
};

export const isLoggedIn = () => {
  return getToken() ? true : false;
};

export const signOut = async () => {
  try {
    // const { logoutRequest } = await import("@defense-station/api-service");
    // await logoutRequest();
    await clearToken();
  } catch (e) {
    await clearToken();
  }
};


export const setRedirectionBackActionForLogin = () => {
  local.setItem("redirect-back", true);
};

export const removeRedirectionBackActionForLogin = () => {
  local.removeItem("redirect-back");
};

export const isRedirectBackActionSet = () => local.getItem("redirect-back");

export const getAccessToken = () => getToken();

export const saveState = (value) => local.setItem("state", value);

export const removeState = () => local.removeItem("state");

export const getState = () => local.getItem("state");

export const signWithSSO = async ({ code, state }) => {
  try {
    const { loginSSORequest } = await import("@defense-station/api-service");
    const res = await loginSSORequest({ code, state });
    saveToken(res?.data?.iam_v1_LoginSSO?.auth_token);
  } catch (e) {
    throw e;
  }
};
