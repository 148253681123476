// @generated by protoc-gen-es v1.10.0 with parameter "import_extension=none,target=ts"
// @generated from file iam.proto (package iam.v1.iam, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message iam.v1.iam.GetUsageRequest
 */
export class GetUsageRequest extends Message<GetUsageRequest> {
  /**
   * @generated from field: string account_id = 1;
   */
  accountId = "";

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  constructor(data?: PartialMessage<GetUsageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.iam.GetUsageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUsageRequest {
    return new GetUsageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUsageRequest {
    return new GetUsageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUsageRequest {
    return new GetUsageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUsageRequest | PlainMessage<GetUsageRequest> | undefined, b: GetUsageRequest | PlainMessage<GetUsageRequest> | undefined): boolean {
    return proto3.util.equals(GetUsageRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.iam.GetUsageResponse
 */
export class GetUsageResponse extends Message<GetUsageResponse> {
  /**
   * @generated from field: int32 users_count = 1;
   */
  usersCount = 0;

  /**
   * @generated from field: int32 groups_count = 2;
   */
  groupsCount = 0;

  /**
   * @generated from field: int32 policy_count = 3;
   */
  policyCount = 0;

  /**
   * @generated from field: int32 keys_count = 4;
   */
  keysCount = 0;

  constructor(data?: PartialMessage<GetUsageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.iam.GetUsageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "users_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "groups_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "policy_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "keys_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUsageResponse {
    return new GetUsageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUsageResponse {
    return new GetUsageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUsageResponse {
    return new GetUsageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetUsageResponse | PlainMessage<GetUsageResponse> | undefined, b: GetUsageResponse | PlainMessage<GetUsageResponse> | undefined): boolean {
    return proto3.util.equals(GetUsageResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.iam.RequestAuthTransferRequest
 */
export class RequestAuthTransferRequest extends Message<RequestAuthTransferRequest> {
  /**
   * @generated from field: string account_id = 1;
   */
  accountId = "";

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  constructor(data?: PartialMessage<RequestAuthTransferRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.iam.RequestAuthTransferRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestAuthTransferRequest {
    return new RequestAuthTransferRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestAuthTransferRequest {
    return new RequestAuthTransferRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestAuthTransferRequest {
    return new RequestAuthTransferRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RequestAuthTransferRequest | PlainMessage<RequestAuthTransferRequest> | undefined, b: RequestAuthTransferRequest | PlainMessage<RequestAuthTransferRequest> | undefined): boolean {
    return proto3.util.equals(RequestAuthTransferRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.iam.RequestAuthTransferResponse
 */
export class RequestAuthTransferResponse extends Message<RequestAuthTransferResponse> {
  /**
   * @generated from field: string transfer_token = 1;
   */
  transferToken = "";

  constructor(data?: PartialMessage<RequestAuthTransferResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.iam.RequestAuthTransferResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "transfer_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestAuthTransferResponse {
    return new RequestAuthTransferResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestAuthTransferResponse {
    return new RequestAuthTransferResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestAuthTransferResponse {
    return new RequestAuthTransferResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RequestAuthTransferResponse | PlainMessage<RequestAuthTransferResponse> | undefined, b: RequestAuthTransferResponse | PlainMessage<RequestAuthTransferResponse> | undefined): boolean {
    return proto3.util.equals(RequestAuthTransferResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.iam.GetTransferAuthRequest
 */
export class GetTransferAuthRequest extends Message<GetTransferAuthRequest> {
  /**
   * @generated from field: string transfer_token = 1;
   */
  transferToken = "";

  /**
   * @generated from field: string recaptcha_code = 2;
   */
  recaptchaCode = "";

  constructor(data?: PartialMessage<GetTransferAuthRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.iam.GetTransferAuthRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "transfer_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "recaptcha_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTransferAuthRequest {
    return new GetTransferAuthRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTransferAuthRequest {
    return new GetTransferAuthRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTransferAuthRequest {
    return new GetTransferAuthRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTransferAuthRequest | PlainMessage<GetTransferAuthRequest> | undefined, b: GetTransferAuthRequest | PlainMessage<GetTransferAuthRequest> | undefined): boolean {
    return proto3.util.equals(GetTransferAuthRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.iam.GetTransferAuthResponse
 */
export class GetTransferAuthResponse extends Message<GetTransferAuthResponse> {
  /**
   * @generated from field: string auth_token = 1;
   */
  authToken = "";

  constructor(data?: PartialMessage<GetTransferAuthResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.iam.GetTransferAuthResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTransferAuthResponse {
    return new GetTransferAuthResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTransferAuthResponse {
    return new GetTransferAuthResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTransferAuthResponse {
    return new GetTransferAuthResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTransferAuthResponse | PlainMessage<GetTransferAuthResponse> | undefined, b: GetTransferAuthResponse | PlainMessage<GetTransferAuthResponse> | undefined): boolean {
    return proto3.util.equals(GetTransferAuthResponse, a, b);
  }
}

