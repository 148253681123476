// @generated by protoc-gen-connect-es v1.6.1 with parameter "import_extension=none,target=ts"
// @generated from file group.proto (package iam.v1.group, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddPolicyToGroupRequest, AddUserToGroupRequest, CreateGroupRequest, CreateGroupResponse, DeleteGroupRequest, GetGroupRequest, GetGroupResponse, ListGroupPoliciesRequest, ListGroupPoliciesResponse, ListGroupsRequest, ListGroupsResponse, ListGroupUsersRequest, ListGroupUsersResponse, RemovePolicyFromGroupRequest, RemoveUserFromGroupRequest, UpdateGroupRequest, UpdateGroupResponse } from "./group_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * genesis options
 *
 * @generated from service iam.v1.group.GroupService
 */
export const GroupService = {
  typeName: "iam.v1.group.GroupService",
  methods: {
    /**
     * @generated from rpc iam.v1.group.GroupService.CreateGroup
     */
    createGroup: {
      name: "CreateGroup",
      I: CreateGroupRequest,
      O: CreateGroupResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.GetGroup
     */
    getGroup: {
      name: "GetGroup",
      I: GetGroupRequest,
      O: GetGroupResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.ListGroups
     */
    listGroups: {
      name: "ListGroups",
      I: ListGroupsRequest,
      O: ListGroupsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.UpdateGroup
     */
    updateGroup: {
      name: "UpdateGroup",
      I: UpdateGroupRequest,
      O: UpdateGroupResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.DeleteGroup
     */
    deleteGroup: {
      name: "DeleteGroup",
      I: DeleteGroupRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.AddPolicyToGroup
     */
    addPolicyToGroup: {
      name: "AddPolicyToGroup",
      I: AddPolicyToGroupRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.RemovePolicyFromGroup
     */
    removePolicyFromGroup: {
      name: "RemovePolicyFromGroup",
      I: RemovePolicyFromGroupRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.AddUserToGroup
     */
    addUserToGroup: {
      name: "AddUserToGroup",
      I: AddUserToGroupRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.RemoveUserFromGroup
     */
    removeUserFromGroup: {
      name: "RemoveUserFromGroup",
      I: RemoveUserFromGroupRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.ListGroupPolicies
     */
    listGroupPolicies: {
      name: "ListGroupPolicies",
      I: ListGroupPoliciesRequest,
      O: ListGroupPoliciesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.ListGroupUsers
     */
    listGroupUsers: {
      name: "ListGroupUsers",
      I: ListGroupUsersRequest,
      O: ListGroupUsersResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

