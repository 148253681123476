import { Interceptor } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import { ClientConfig, createUserServiceClient, EmptyCredentialProvider, JwtCredentialProvider, getToken } from "@/ds-apis"


// Redirect function to login page
const redirectToLogin = () => {
  window.location.href = "/signin"; // Redirect to your login route
};

const authMiddleware: Interceptor = (next) => async (req) => {
  const token = getToken();

  // If no token, redirect to login
  if (!token) {
    redirectToLogin();
    // return Promise.reject(new Error("No valid token, redirecting to login"));
  }

  // Set the Authorization header
  req.header.set("Authorization", `Bearer ${token}`);

  try {
    // Continue with the request
    return await next(req);
  } catch (error: any) {
    // Check if error is 401 Unauthorized
    if (error.code === "Unauthenticated" || error.statusCode === 401) {
      redirectToLogin(); // Redirect if unauthorized
    }
    else if(error.message.includes("user not logged in")) {
      redirectToLogin();
      return;
  }
    throw error; // Re-throw other errors
  }
};


const config: ClientConfig = {
  region: "",
  service: "",
  endpoint: "https://iam.us.api.dev.defencestation.ca/",
  ssl: false,
}

const clientCredentials = new JwtCredentialProvider({
  token: "asdsa",
})
// Here we make the client itself, combining the service
// definition with the transport.
export const userClient = createUserServiceClient(config, clientCredentials, [authMiddleware])

const emptyCredentials: EmptyCredentialProvider = new EmptyCredentialProvider();
// Here we make the client itself, combining the service
// definition with the transport.
export const authClient = createUserServiceClient(config, emptyCredentials)

