import { ICredentialProvider } from './ICredentialProvider';

export interface JwtCredentialOptions {
  token: string;
  headerName?: string; // Default: 'Authorization'
  prefix?: string; // Default: 'Bearer'
}

export class JwtCredentialProvider implements ICredentialProvider {
  private token: string;
  private headerName: string;
  private prefix: string;

  constructor(options: JwtCredentialOptions) {
    this.token = options.token;
    this.headerName = options.headerName || 'Authorization';
    this.prefix = options.prefix || 'Bearer';
  }

  async getAuthHeaders(): Promise<Record<string, string>> {
    return {
      [this.headerName]: `${this.prefix} ${this.token}`,
    };
  }
}