// @generated by protoc-gen-connect-es v1.6.1 with parameter "import_extension=none,target=ts"
// @generated from file key.proto (package iam.v1.key, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateAccessKeyRequest, CreateAccessKeyResponse, DeleteAccessKeyRequest, GetAccessKeyRequest, GetAccessKeyResponse, ListAccessKeysRequest, ListAccessKeysResponse, UpdateAccessKeyRequest, UpdateAccessKeyResponse } from "./key_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service iam.v1.key.AccessKeyService
 */
export const AccessKeyService = {
  typeName: "iam.v1.key.AccessKeyService",
  methods: {
    /**
     * @generated from rpc iam.v1.key.AccessKeyService.CreateAccessKey
     */
    createAccessKey: {
      name: "CreateAccessKey",
      I: CreateAccessKeyRequest,
      O: CreateAccessKeyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.key.AccessKeyService.GetAccessKey
     */
    getAccessKey: {
      name: "GetAccessKey",
      I: GetAccessKeyRequest,
      O: GetAccessKeyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.key.AccessKeyService.ListAccessKeys
     */
    listAccessKeys: {
      name: "ListAccessKeys",
      I: ListAccessKeysRequest,
      O: ListAccessKeysResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.key.AccessKeyService.UpdateAccessKey
     */
    updateAccessKey: {
      name: "UpdateAccessKey",
      I: UpdateAccessKeyRequest,
      O: UpdateAccessKeyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.key.AccessKeyService.DeleteAccessKey
     */
    deleteAccessKey: {
      name: "DeleteAccessKey",
      I: DeleteAccessKeyRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

