// @generated by protoc-gen-es v1.10.0 with parameter "import_extension=none,target=ts"
// @generated from file types.proto (package iam.v1.types, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum iam.v1.types.AccountType
 */
export enum AccountType {
  /**
   * @generated from enum value: ACCOUNT_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ACCOUNT_TYPE_PERSONAL = 1;
   */
  PERSONAL = 1,

  /**
   * @generated from enum value: ACCOUNT_TYPE_BUSINESS = 2;
   */
  BUSINESS = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(AccountType)
proto3.util.setEnumType(AccountType, "iam.v1.types.AccountType", [
  { no: 0, name: "ACCOUNT_TYPE_UNSPECIFIED" },
  { no: 1, name: "ACCOUNT_TYPE_PERSONAL" },
  { no: 2, name: "ACCOUNT_TYPE_BUSINESS" },
]);

/**
 * @generated from enum iam.v1.types.UserType
 */
export enum UserType {
  /**
   * @generated from enum value: USER_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: USER_TYPE_ROOT = 1;
   */
  ROOT = 1,

  /**
   * @generated from enum value: USER_TYPE_IAM = 2;
   */
  IAM = 2,

  /**
   * @generated from enum value: USER_TYPE_SSO = 3;
   */
  SSO = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(UserType)
proto3.util.setEnumType(UserType, "iam.v1.types.UserType", [
  { no: 0, name: "USER_TYPE_UNSPECIFIED" },
  { no: 1, name: "USER_TYPE_ROOT" },
  { no: 2, name: "USER_TYPE_IAM" },
  { no: 3, name: "USER_TYPE_SSO" },
]);

/**
 * @generated from enum iam.v1.types.UserAccessType
 */
export enum UserAccessType {
  /**
   * @generated from enum value: USER_ACCESS_TYPE_MANAGEMENT_UNSPECIFIED = 0;
   */
  MANAGEMENT_UNSPECIFIED = 0,

  /**
   * @generated from enum value: USER_ACCESS_TYPE_PROGRAMATIC = 1;
   */
  PROGRAMATIC = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(UserAccessType)
proto3.util.setEnumType(UserAccessType, "iam.v1.types.UserAccessType", [
  { no: 0, name: "USER_ACCESS_TYPE_MANAGEMENT_UNSPECIFIED" },
  { no: 1, name: "USER_ACCESS_TYPE_PROGRAMATIC" },
]);

/**
 * THERE SHOULD BE NO SERVICE IN THIS FILE.
 * Global Messages for this service
 * LastEvaluted used for query pagination
 *
 * @generated from message iam.v1.types.LastEvaluated
 */
export class LastEvaluated extends Message<LastEvaluated> {
  /**
   * @generated from field: string pk = 1;
   */
  pk = "";

  /**
   * @generated from field: string sk = 2;
   */
  sk = "";

  /**
   * @generated from field: string gsi1_pk = 3;
   */
  gsi1Pk = "";

  /**
   * @generated from field: string ssi1_sk = 4;
   */
  ssi1Sk = "";

  constructor(data?: PartialMessage<LastEvaluated>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.LastEvaluated";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pk", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sk", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "gsi1_pk", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "ssi1_sk", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LastEvaluated {
    return new LastEvaluated().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LastEvaluated {
    return new LastEvaluated().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LastEvaluated {
    return new LastEvaluated().fromJsonString(jsonString, options);
  }

  static equals(a: LastEvaluated | PlainMessage<LastEvaluated> | undefined, b: LastEvaluated | PlainMessage<LastEvaluated> | undefined): boolean {
    return proto3.util.equals(LastEvaluated, a, b);
  }
}

/**
 * @generated from message iam.v1.types.Account
 */
export class Account extends Message<Account> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string alias = 2;
   */
  alias = "";

  /**
   * @generated from field: string account_id = 3;
   */
  accountId = "";

  /**
   * @generated from field: string root_user = 4;
   */
  rootUser = "";

  constructor(data?: PartialMessage<Account>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Account";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "alias", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "root_user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Account {
    return new Account().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJsonString(jsonString, options);
  }

  static equals(a: Account | PlainMessage<Account> | undefined, b: Account | PlainMessage<Account> | undefined): boolean {
    return proto3.util.equals(Account, a, b);
  }
}

/**
 * @generated from message iam.v1.types.Principal
 */
export class Principal extends Message<Principal> {
  constructor(data?: PartialMessage<Principal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Principal";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Principal {
    return new Principal().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Principal {
    return new Principal().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Principal {
    return new Principal().fromJsonString(jsonString, options);
  }

  static equals(a: Principal | PlainMessage<Principal> | undefined, b: Principal | PlainMessage<Principal> | undefined): boolean {
    return proto3.util.equals(Principal, a, b);
  }
}

/**
 * @generated from message iam.v1.types.Group
 */
export class Group extends Message<Group> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string group_name = 2;
   */
  groupName = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: string group_type = 4;
   */
  groupType = "";

  /**
   * @generated from field: string created_at = 5;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 6;
   */
  updatedAt = "";

  /**
   * @generated from field: map<string, string> metadata = 7;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<Group>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Group";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "group_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "group_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Group {
    return new Group().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Group {
    return new Group().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Group {
    return new Group().fromJsonString(jsonString, options);
  }

  static equals(a: Group | PlainMessage<Group> | undefined, b: Group | PlainMessage<Group> | undefined): boolean {
    return proto3.util.equals(Group, a, b);
  }
}

/**
 * @generated from message iam.v1.types.User
 */
export class User extends Message<User> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string account_id = 2;
   */
  accountId = "";

  /**
   * @generated from field: string username = 3;
   */
  username = "";

  /**
   * @generated from field: string email = 4;
   */
  email = "";

  /**
   * @generated from field: string first_name = 5;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 6;
   */
  lastName = "";

  /**
   * @generated from field: iam.v1.types.AccountType account_type = 7;
   */
  accountType = AccountType.UNSPECIFIED;

  /**
   * @generated from field: iam.v1.types.UserType user_type = 8;
   */
  userType = UserType.UNSPECIFIED;

  /**
   * @generated from field: iam.v1.types.UserAccessType access_type = 9;
   */
  accessType = UserAccessType.MANAGEMENT_UNSPECIFIED;

  /**
   * @generated from field: string created_at = 10;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 11;
   */
  updatedAt = "";

  /**
   * @generated from field: map<string, string> metadata = 12;
   */
  metadata: { [key: string]: string } = {};

  /**
   * @generated from field: bool email_verified = 13;
   */
  emailVerified = false;

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "account_type", kind: "enum", T: proto3.getEnumType(AccountType) },
    { no: 8, name: "user_type", kind: "enum", T: proto3.getEnumType(UserType) },
    { no: 9, name: "access_type", kind: "enum", T: proto3.getEnumType(UserAccessType) },
    { no: 10, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 13, name: "email_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

/**
 * @generated from message iam.v1.types.AccessKey
 */
export class AccessKey extends Message<AccessKey> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string account_id = 2;
   */
  accountId = "";

  /**
   * @generated from field: string access_key = 3;
   */
  accessKey = "";

  /**
   * @generated from field: string secret_key = 4;
   */
  secretKey = "";

  /**
   * @generated from field: bool active = 5;
   */
  active = false;

  /**
   * @generated from field: string created_at = 6;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 7;
   */
  updatedAt = "";

  constructor(data?: PartialMessage<AccessKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.AccessKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "access_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "secret_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccessKey {
    return new AccessKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccessKey {
    return new AccessKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccessKey {
    return new AccessKey().fromJsonString(jsonString, options);
  }

  static equals(a: AccessKey | PlainMessage<AccessKey> | undefined, b: AccessKey | PlainMessage<AccessKey> | undefined): boolean {
    return proto3.util.equals(AccessKey, a, b);
  }
}

/**
 * @generated from message iam.v1.types.Alias
 */
export class Alias extends Message<Alias> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string account_id = 2;
   */
  accountId = "";

  /**
   * @generated from field: string alias = 3;
   */
  alias = "";

  /**
   * @generated from field: string created_at = 4;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 5;
   */
  updatedAt = "";

  constructor(data?: PartialMessage<Alias>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Alias";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "alias", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Alias {
    return new Alias().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Alias {
    return new Alias().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Alias {
    return new Alias().fromJsonString(jsonString, options);
  }

  static equals(a: Alias | PlainMessage<Alias> | undefined, b: Alias | PlainMessage<Alias> | undefined): boolean {
    return proto3.util.equals(Alias, a, b);
  }
}

/**
 * policy messages
 *
 * @generated from message iam.v1.types.StringCondition
 */
export class StringCondition extends Message<StringCondition> {
  /**
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * @generated from field: repeated string values = 2;
   */
  values: string[] = [];

  constructor(data?: PartialMessage<StringCondition>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.StringCondition";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StringCondition {
    return new StringCondition().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StringCondition {
    return new StringCondition().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StringCondition {
    return new StringCondition().fromJsonString(jsonString, options);
  }

  static equals(a: StringCondition | PlainMessage<StringCondition> | undefined, b: StringCondition | PlainMessage<StringCondition> | undefined): boolean {
    return proto3.util.equals(StringCondition, a, b);
  }
}

/**
 * @generated from message iam.v1.types.Int32Condition
 */
export class Int32Condition extends Message<Int32Condition> {
  /**
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * @generated from field: repeated int32 values = 2;
   */
  values: number[] = [];

  constructor(data?: PartialMessage<Int32Condition>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Int32Condition";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "values", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Int32Condition {
    return new Int32Condition().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Int32Condition {
    return new Int32Condition().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Int32Condition {
    return new Int32Condition().fromJsonString(jsonString, options);
  }

  static equals(a: Int32Condition | PlainMessage<Int32Condition> | undefined, b: Int32Condition | PlainMessage<Int32Condition> | undefined): boolean {
    return proto3.util.equals(Int32Condition, a, b);
  }
}

/**
 * @generated from message iam.v1.types.BoolCondition
 */
export class BoolCondition extends Message<BoolCondition> {
  /**
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * @generated from field: bool value = 2;
   */
  value = false;

  constructor(data?: PartialMessage<BoolCondition>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.BoolCondition";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BoolCondition {
    return new BoolCondition().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BoolCondition {
    return new BoolCondition().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BoolCondition {
    return new BoolCondition().fromJsonString(jsonString, options);
  }

  static equals(a: BoolCondition | PlainMessage<BoolCondition> | undefined, b: BoolCondition | PlainMessage<BoolCondition> | undefined): boolean {
    return proto3.util.equals(BoolCondition, a, b);
  }
}

/**
 * @generated from message iam.v1.types.Conditions
 */
export class Conditions extends Message<Conditions> {
  /**
   * @generated from field: repeated iam.v1.types.StringCondition string_equals = 1;
   */
  stringEquals: StringCondition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.StringCondition string_not_equals = 2;
   */
  stringNotEquals: StringCondition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.StringCondition string_like = 3;
   */
  stringLike: StringCondition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.Int32Condition numeric_equals = 4;
   */
  numericEquals: Int32Condition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.Int32Condition numeric_not_equals = 5;
   */
  numericNotEquals: Int32Condition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.Int32Condition numeric_less_than = 6;
   */
  numericLessThan: Int32Condition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.Int32Condition numeric_greater_than = 7;
   */
  numericGreaterThan: Int32Condition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.Int32Condition numeric_less_than_equals = 8;
   */
  numericLessThanEquals: Int32Condition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.Int32Condition numeric_greater_than_equals = 9;
   */
  numericGreaterThanEquals: Int32Condition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.BoolCondition bool = 10;
   */
  bool: BoolCondition[] = [];

  constructor(data?: PartialMessage<Conditions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Conditions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "string_equals", kind: "message", T: StringCondition, repeated: true },
    { no: 2, name: "string_not_equals", kind: "message", T: StringCondition, repeated: true },
    { no: 3, name: "string_like", kind: "message", T: StringCondition, repeated: true },
    { no: 4, name: "numeric_equals", kind: "message", T: Int32Condition, repeated: true },
    { no: 5, name: "numeric_not_equals", kind: "message", T: Int32Condition, repeated: true },
    { no: 6, name: "numeric_less_than", kind: "message", T: Int32Condition, repeated: true },
    { no: 7, name: "numeric_greater_than", kind: "message", T: Int32Condition, repeated: true },
    { no: 8, name: "numeric_less_than_equals", kind: "message", T: Int32Condition, repeated: true },
    { no: 9, name: "numeric_greater_than_equals", kind: "message", T: Int32Condition, repeated: true },
    { no: 10, name: "bool", kind: "message", T: BoolCondition, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Conditions {
    return new Conditions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Conditions {
    return new Conditions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Conditions {
    return new Conditions().fromJsonString(jsonString, options);
  }

  static equals(a: Conditions | PlainMessage<Conditions> | undefined, b: Conditions | PlainMessage<Conditions> | undefined): boolean {
    return proto3.util.equals(Conditions, a, b);
  }
}

/**
 * Defines a policy statement.
 *
 * @generated from message iam.v1.types.Statement
 */
export class Statement extends Message<Statement> {
  /**
   * Statement ID
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string effect = 2;
   */
  effect = "";

  /**
   * @generated from field: repeated string actions = 3;
   */
  actions: string[] = [];

  /**
   * @generated from field: repeated string not_actions = 4;
   */
  notActions: string[] = [];

  /**
   * @generated from field: repeated string resources = 5;
   */
  resources: string[] = [];

  /**
   * @generated from field: repeated string not_resources = 6;
   */
  notResources: string[] = [];

  /**
   * @generated from field: repeated iam.v1.types.Conditions conditions = 7;
   */
  conditions: Conditions[] = [];

  constructor(data?: PartialMessage<Statement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Statement";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "effect", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "actions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "not_actions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "resources", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "not_resources", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "conditions", kind: "message", T: Conditions, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Statement {
    return new Statement().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Statement {
    return new Statement().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Statement {
    return new Statement().fromJsonString(jsonString, options);
  }

  static equals(a: Statement | PlainMessage<Statement> | undefined, b: Statement | PlainMessage<Statement> | undefined): boolean {
    return proto3.util.equals(Statement, a, b);
  }
}

/**
 * Represents the overall policy.
 *
 * @generated from message iam.v1.types.Policy
 */
export class Policy extends Message<Policy> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string policy_version = 2;
   */
  policyVersion = "";

  /**
   * @generated from field: string policy_name = 3;
   */
  policyName = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: repeated string scopes = 5;
   */
  scopes: string[] = [];

  /**
   * @generated from field: repeated iam.v1.types.Statement statements = 6;
   */
  statements: Statement[] = [];

  /**
   * @generated from field: string created_at = 7;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 8;
   */
  updatedAt = "";

  /**
   * @generated from field: map<string, string> metadata = 9;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<Policy>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Policy";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "policy_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "policy_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "scopes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "statements", kind: "message", T: Statement, repeated: true },
    { no: 7, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Policy {
    return new Policy().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Policy {
    return new Policy().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Policy {
    return new Policy().fromJsonString(jsonString, options);
  }

  static equals(a: Policy | PlainMessage<Policy> | undefined, b: Policy | PlainMessage<Policy> | undefined): boolean {
    return proto3.util.equals(Policy, a, b);
  }
}

