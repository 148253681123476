// @generated by protoc-gen-connect-es v1.6.1 with parameter "import_extension=none,target=ts"
// @generated from file iam.proto (package iam.v1.iam, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetTransferAuthRequest, GetTransferAuthResponse, GetUsageRequest, GetUsageResponse, RequestAuthTransferRequest, RequestAuthTransferResponse } from "./iam_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service iam.v1.iam.IAMService
 */
export const IAMService = {
  typeName: "iam.v1.iam.IAMService",
  methods: {
    /**
     * @generated from rpc iam.v1.iam.IAMService.GetUsage
     */
    getUsage: {
      name: "GetUsage",
      I: GetUsageRequest,
      O: GetUsageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.iam.IAMService.RequestAuthTransfer
     */
    requestAuthTransfer: {
      name: "RequestAuthTransfer",
      I: RequestAuthTransferRequest,
      O: RequestAuthTransferResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.iam.IAMService.GetTransferAuth
     */
    getTransferAuth: {
      name: "GetTransferAuth",
      I: GetTransferAuthRequest,
      O: GetTransferAuthResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

