// @generated by protoc-gen-connect-es v1.6.1 with parameter "import_extension=none,target=ts"
// @generated from file alias.proto (package iam.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateAliasRequest, CreateAliasResponse, DeleteAliasRequest, GetAliasRequest, GetAliasResponse, UpdateAliasRequest, UpdateAliasResponse } from "./alias_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service iam.v1.AliasService
 */
export const AliasService = {
  typeName: "iam.v1.AliasService",
  methods: {
    /**
     * @generated from rpc iam.v1.AliasService.CreateAlias
     */
    createAlias: {
      name: "CreateAlias",
      I: CreateAliasRequest,
      O: CreateAliasResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.AliasService.GetAlias
     */
    getAlias: {
      name: "GetAlias",
      I: GetAliasRequest,
      O: GetAliasResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.AliasService.UpdateAlias
     */
    updateAlias: {
      name: "UpdateAlias",
      I: UpdateAliasRequest,
      O: UpdateAliasResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.AliasService.DeleteAlias
     */
    deleteAlias: {
      name: "DeleteAlias",
      I: DeleteAliasRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

