import { ConnectTransportOptions, createConnectTransport } from "@connectrpc/connect-web";
// import { createConnectTransport as createConnectTransportNode, ConnectTransportOptions as ConnectTransportOptionsNode } from "@connectrpc/connect-node";
import { AuthConfig } from "./auth/types";
import { Interceptor, Transport } from "@connectrpc/connect";
import { jwtInterceptor, keyInterceptor, unauthorizedInterceptor } from './auth/interceptors';
import fetch from 'node-fetch';
import { ClientConfig } from "./config/ClientConfig";
import { ICredentialProvider } from "./credentials";
import { resolveEndpoint } from "./config/EndpointResolver";

/**
 * Creates a ConnectRPC transport with optional authentication using credential providers.
 * @param config - Client configuration including service, region, etc.
 * @param credentialProvider - Credential provider for authentication.
 * @param additionalInterceptors - Optional additional interceptors provided by the user.
 * @returns Configured ConnectRPC transport.
 */
export const createAuthorizedTransport = (
    config: ClientConfig,
    credentialProvider: ICredentialProvider,
    additionalInterceptors?: Interceptor[]
): Transport => {
    // Resolve the service endpoint based on the configuration
    const serviceUrl = resolveEndpoint(config);

    // Define an interceptor to inject authentication headers
    const authInterceptor: Interceptor = (next) => async (req) => {
        try {
            // Retrieve authentication headers from the credential provider
            const authHeaders = await credentialProvider.getAuthHeaders(req.message);

            // Inject each header into the request
            for (const [key, value] of Object.entries(authHeaders)) {
                if (value) {
                    req.header.set(key, value);
                }
            }
        } catch (error) {
            console.error("Failed to retrieve auth headers:", error);
            throw error;
        }

        // Proceed to the next interceptor or send the request
        return next(req);
    };

    // Initialize the list of interceptors
    const interceptors: Interceptor[] = [
        authInterceptor,         // Authentication interceptor
        unauthorizedInterceptor, // Handle unauthorized errors
    ];

    // Append any additional user-provided interceptors
    if (additionalInterceptors && additionalInterceptors.length > 0) {
        interceptors.push(...additionalInterceptors);
    }


    const transportOptions: ConnectTransportOptions  = {
        baseUrl: serviceUrl,
        interceptors,
    }
    // const transportOptionsNode: ConnectTransportOptionsNode  = {
    //     baseUrl: serviceUrl,
    //     httpVersion: "1.1",
    //     interceptors,
    // }
    // Create and return the configured ConnectRPC transport
    return createConnectTransport(transportOptions);
    // typeof window === "undefined" ? 
    // createConnectTransportNode(transportOptionsNode) 
    // {}
    // : 
    // createConnectTransport(transportOptions);
};