import { createPromiseClient, Interceptor, PromiseClient } from "@connectrpc/connect";
import { createAuthorizedTransport } from "../../../../../src/transport";
import { UserService } from "./user_connect";
import { AuthConfig } from "../../../../../src/auth/types";
import { SERVICE_URLS } from "../../../../../src/config";
import { ClientConfig } from "../../../../../src/config/ClientConfig";
import { ICredentialProvider } from "../../../../../src/credentials";

/**
 * Factory function to create a ServiceA client.
 *
 * @param {string} baseUrl - The base URL for ServiceA.
 * @param {Array<Function>} additionalInterceptors - Optional additional interceptors.
 * @returns {PromiseClient} The configured ServiceA client.
 */
export const createUserServiceClient = (config: ClientConfig,
    credentialProvider: ICredentialProvider, additionalInterceptors: Interceptor[] = []) => {
    const transport = createAuthorizedTransport(config, credentialProvider, additionalInterceptors);
    return createPromiseClient(UserService, transport);
};