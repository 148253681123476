import { Interceptor } from '@connectrpc/connect';

/**
 * JWT Interceptor
 * Adds the JWT token to the Authorization header.
 */
export const jwtInterceptor = (token: string): Interceptor => {
    return (next) => async (req) => {
        if (!token) {
            throw new Error('No JWT token provided, redirecting to login');
        }

        req.header.set('Authorization', `Bearer ${token}`);
        return next(req);
    };
};

/**
 * Access Key & Secret Key Interceptor
 * Adds a signature to the Authorization header.
 * The signature generation logic should match your backend requirements.
 */
export const keyInterceptor = (accessKey: string, secretKey: string): Interceptor => {
    return (next) => async (req) => {
        if (!accessKey || !secretKey) {
            throw new Error('Access key or secret key missing, redirecting to login');
        }

        // Example: Generate a simple HMAC SHA256 signature
        // Adjust the signature generation as per your backend's requirements
        const crypto = window.crypto || (window as any).msCrypto;
        const encoder = new TextEncoder();
        const data = encoder.encode(req.method + JSON.stringify(req.contextValues));
        
        const key = await crypto.subtle.importKey(
            'raw',
            encoder.encode(secretKey),
            { name: 'HMAC', hash: 'SHA-256' },
            false,
            ['sign']
        );

        const signature = await crypto.subtle.sign('HMAC', key, data);
        const signatureHex = Array.from(new Uint8Array(signature))
            .map(b => b.toString(16).padStart(2, '0'))
            .join('');

        req.header.set('Authorization', `AccessKey ${accessKey}:Signature ${signatureHex}`);
        return next(req);
    };
};

/**
 * Unauthorized Error Handler Interceptor
 * Redirects to login on 401 Unauthorized errors.
 */
export const unauthorizedInterceptor: Interceptor = (next) => async (req) => {
    try {
        return await next(req);
    } catch (error: any) {
        if (error.code === 'Unauthenticated' || error.statusCode === 401) {
            throw new Error('Not authenticated');
        }
        throw error;
    }
};