// @generated by protoc-gen-es v1.10.0 with parameter "import_extension=none,target=ts"
// @generated from file policy.proto (package iam.v1.policy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Policy, Statement } from "../../types/types_pb";

/**
 * @generated from message iam.v1.policy.CreatePolicyRequest
 */
export class CreatePolicyRequest extends Message<CreatePolicyRequest> {
  /**
   * @generated from field: string policy_version = 1;
   */
  policyVersion = "";

  /**
   * @generated from field: string policy_name = 2;
   */
  policyName = "";

  /**
   * [genesis.Type.Dri = "true"];
   *
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: repeated iam.v1.types.Statement statements = 4;
   */
  statements: Statement[] = [];

  constructor(data?: PartialMessage<CreatePolicyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.policy.CreatePolicyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "policy_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "statements", kind: "message", T: Statement, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePolicyRequest {
    return new CreatePolicyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePolicyRequest {
    return new CreatePolicyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePolicyRequest {
    return new CreatePolicyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePolicyRequest | PlainMessage<CreatePolicyRequest> | undefined, b: CreatePolicyRequest | PlainMessage<CreatePolicyRequest> | undefined): boolean {
    return proto3.util.equals(CreatePolicyRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.policy.CreatePolicyResponse
 */
export class CreatePolicyResponse extends Message<CreatePolicyResponse> {
  /**
   * @generated from field: iam.v1.types.Policy policy = 1;
   */
  policy?: Policy;

  constructor(data?: PartialMessage<CreatePolicyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.policy.CreatePolicyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy", kind: "message", T: Policy },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePolicyResponse {
    return new CreatePolicyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePolicyResponse {
    return new CreatePolicyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePolicyResponse {
    return new CreatePolicyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePolicyResponse | PlainMessage<CreatePolicyResponse> | undefined, b: CreatePolicyResponse | PlainMessage<CreatePolicyResponse> | undefined): boolean {
    return proto3.util.equals(CreatePolicyResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.policy.GetPolicyRequest
 */
export class GetPolicyRequest extends Message<GetPolicyRequest> {
  /**
   * @generated from field: string policy_dri = 1;
   */
  policyDri = "";

  constructor(data?: PartialMessage<GetPolicyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.policy.GetPolicyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPolicyRequest {
    return new GetPolicyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPolicyRequest {
    return new GetPolicyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPolicyRequest {
    return new GetPolicyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPolicyRequest | PlainMessage<GetPolicyRequest> | undefined, b: GetPolicyRequest | PlainMessage<GetPolicyRequest> | undefined): boolean {
    return proto3.util.equals(GetPolicyRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.policy.GetPolicyResponse
 */
export class GetPolicyResponse extends Message<GetPolicyResponse> {
  /**
   * @generated from field: iam.v1.types.Policy policy = 1;
   */
  policy?: Policy;

  constructor(data?: PartialMessage<GetPolicyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.policy.GetPolicyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy", kind: "message", T: Policy },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPolicyResponse {
    return new GetPolicyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPolicyResponse {
    return new GetPolicyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPolicyResponse {
    return new GetPolicyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPolicyResponse | PlainMessage<GetPolicyResponse> | undefined, b: GetPolicyResponse | PlainMessage<GetPolicyResponse> | undefined): boolean {
    return proto3.util.equals(GetPolicyResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.policy.ListPoliciesRequest
 */
export class ListPoliciesRequest extends Message<ListPoliciesRequest> {
  /**
   * @generated from field: string policy_name = 1;
   */
  policyName = "";

  /**
   * @generated from field: bool include_ds_managed = 2;
   */
  includeDsManaged = false;

  /**
   * @generated from field: int32 page_size = 3;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 4;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListPoliciesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.policy.ListPoliciesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "include_ds_managed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPoliciesRequest {
    return new ListPoliciesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPoliciesRequest {
    return new ListPoliciesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPoliciesRequest {
    return new ListPoliciesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListPoliciesRequest | PlainMessage<ListPoliciesRequest> | undefined, b: ListPoliciesRequest | PlainMessage<ListPoliciesRequest> | undefined): boolean {
    return proto3.util.equals(ListPoliciesRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.policy.ListPoliciesResponse
 */
export class ListPoliciesResponse extends Message<ListPoliciesResponse> {
  /**
   * @generated from field: repeated iam.v1.types.Policy policies = 1;
   */
  policies: Policy[] = [];

  constructor(data?: PartialMessage<ListPoliciesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.policy.ListPoliciesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policies", kind: "message", T: Policy, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPoliciesResponse {
    return new ListPoliciesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPoliciesResponse {
    return new ListPoliciesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPoliciesResponse {
    return new ListPoliciesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListPoliciesResponse | PlainMessage<ListPoliciesResponse> | undefined, b: ListPoliciesResponse | PlainMessage<ListPoliciesResponse> | undefined): boolean {
    return proto3.util.equals(ListPoliciesResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.policy.UpdatePolicyRequest
 */
export class UpdatePolicyRequest extends Message<UpdatePolicyRequest> {
  /**
   * @generated from field: string policy_dri = 1;
   */
  policyDri = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: repeated iam.v1.types.Statement statements = 3;
   */
  statements: Statement[] = [];

  constructor(data?: PartialMessage<UpdatePolicyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.policy.UpdatePolicyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "statements", kind: "message", T: Statement, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePolicyRequest {
    return new UpdatePolicyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePolicyRequest {
    return new UpdatePolicyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePolicyRequest {
    return new UpdatePolicyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePolicyRequest | PlainMessage<UpdatePolicyRequest> | undefined, b: UpdatePolicyRequest | PlainMessage<UpdatePolicyRequest> | undefined): boolean {
    return proto3.util.equals(UpdatePolicyRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.policy.UpdatePolicyResponse
 */
export class UpdatePolicyResponse extends Message<UpdatePolicyResponse> {
  /**
   * @generated from field: iam.v1.types.Policy policy = 1;
   */
  policy?: Policy;

  constructor(data?: PartialMessage<UpdatePolicyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.policy.UpdatePolicyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy", kind: "message", T: Policy },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePolicyResponse {
    return new UpdatePolicyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePolicyResponse {
    return new UpdatePolicyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePolicyResponse {
    return new UpdatePolicyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePolicyResponse | PlainMessage<UpdatePolicyResponse> | undefined, b: UpdatePolicyResponse | PlainMessage<UpdatePolicyResponse> | undefined): boolean {
    return proto3.util.equals(UpdatePolicyResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.policy.DeletePolicyRequest
 */
export class DeletePolicyRequest extends Message<DeletePolicyRequest> {
  /**
   * @generated from field: string policy_dri = 1;
   */
  policyDri = "";

  constructor(data?: PartialMessage<DeletePolicyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.policy.DeletePolicyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePolicyRequest {
    return new DeletePolicyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePolicyRequest {
    return new DeletePolicyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeletePolicyRequest {
    return new DeletePolicyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeletePolicyRequest | PlainMessage<DeletePolicyRequest> | undefined, b: DeletePolicyRequest | PlainMessage<DeletePolicyRequest> | undefined): boolean {
    return proto3.util.equals(DeletePolicyRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.policy.InternalListUserPoliciesRequest
 */
export class InternalListUserPoliciesRequest extends Message<InternalListUserPoliciesRequest> {
  /**
   * @generated from field: string account_id = 1;
   */
  accountId = "";

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  /**
   * @generated from field: int32 page_size = 3;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 4;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<InternalListUserPoliciesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.policy.InternalListUserPoliciesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InternalListUserPoliciesRequest {
    return new InternalListUserPoliciesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InternalListUserPoliciesRequest {
    return new InternalListUserPoliciesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InternalListUserPoliciesRequest {
    return new InternalListUserPoliciesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InternalListUserPoliciesRequest | PlainMessage<InternalListUserPoliciesRequest> | undefined, b: InternalListUserPoliciesRequest | PlainMessage<InternalListUserPoliciesRequest> | undefined): boolean {
    return proto3.util.equals(InternalListUserPoliciesRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.policy.InternalListUserPoliciesResponse
 */
export class InternalListUserPoliciesResponse extends Message<InternalListUserPoliciesResponse> {
  /**
   * @generated from field: repeated iam.v1.types.Policy policies = 1;
   */
  policies: Policy[] = [];

  constructor(data?: PartialMessage<InternalListUserPoliciesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.policy.InternalListUserPoliciesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policies", kind: "message", T: Policy, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InternalListUserPoliciesResponse {
    return new InternalListUserPoliciesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InternalListUserPoliciesResponse {
    return new InternalListUserPoliciesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InternalListUserPoliciesResponse {
    return new InternalListUserPoliciesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InternalListUserPoliciesResponse | PlainMessage<InternalListUserPoliciesResponse> | undefined, b: InternalListUserPoliciesResponse | PlainMessage<InternalListUserPoliciesResponse> | undefined): boolean {
    return proto3.util.equals(InternalListUserPoliciesResponse, a, b);
  }
}

