// @generated by protoc-gen-connect-es v1.6.1 with parameter "import_extension=none,target=ts"
// @generated from file policy.proto (package iam.v1.policy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreatePolicyRequest, CreatePolicyResponse, DeletePolicyRequest, GetPolicyRequest, GetPolicyResponse, InternalListUserPoliciesRequest, InternalListUserPoliciesResponse, ListPoliciesRequest, ListPoliciesResponse, UpdatePolicyRequest, UpdatePolicyResponse } from "./policy_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service iam.v1.policy.PolicyService
 */
export const PolicyService = {
  typeName: "iam.v1.policy.PolicyService",
  methods: {
    /**
     * @generated from rpc iam.v1.policy.PolicyService.CreatePolicy
     */
    createPolicy: {
      name: "CreatePolicy",
      I: CreatePolicyRequest,
      O: CreatePolicyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.policy.PolicyService.GetPolicy
     */
    getPolicy: {
      name: "GetPolicy",
      I: GetPolicyRequest,
      O: GetPolicyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.policy.PolicyService.ListPolicies
     */
    listPolicies: {
      name: "ListPolicies",
      I: ListPoliciesRequest,
      O: ListPoliciesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.policy.PolicyService.UpdatePolicy
     */
    updatePolicy: {
      name: "UpdatePolicy",
      I: UpdatePolicyRequest,
      O: UpdatePolicyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.policy.PolicyService.DeletePolicy
     */
    deletePolicy: {
      name: "DeletePolicy",
      I: DeletePolicyRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.policy.PolicyService.InternalListUserPolicies
     */
    internalListUserPolicies: {
      name: "InternalListUserPolicies",
      I: InternalListUserPoliciesRequest,
      O: InternalListUserPoliciesResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

