/**
 * Retrieves the authentication token from localStorage.
 * @returns {string|null} The auth token or null if not found.
 */
export const getToken = () => {
    return localStorage.getItem("authToken");
};

/**
 * Saves the authentication token to localStorage.
 * @param {string} token - The auth token to save.
 */
export const saveToken = (token: string) => {
    localStorage.setItem("authToken", token);
};

/**
 * Clears the authentication token from localStorage.
 */
export const clearToken = () => {
    localStorage.removeItem("authToken");
};

/**
 * Redirects the user to the login page.
 */
export const redirectToLogin = () => {
    window.location.href = "/login"; // Customize the login path as needed
};