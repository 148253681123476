// @generated by protoc-gen-es v1.10.0 with parameter "import_extension=none,target=ts"
// @generated from file group.proto (package iam.v1.group, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Group, Policy, User } from "../../types/types_pb";

/**
 * @generated from message iam.v1.group.CreateGroupRequest
 */
export class CreateGroupRequest extends Message<CreateGroupRequest> {
  /**
   * @generated from field: string group_name = 1;
   */
  groupName = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: string group_type = 3;
   */
  groupType = "";

  constructor(data?: PartialMessage<CreateGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.group.CreateGroupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "group_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "group_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateGroupRequest {
    return new CreateGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateGroupRequest {
    return new CreateGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateGroupRequest {
    return new CreateGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateGroupRequest | PlainMessage<CreateGroupRequest> | undefined, b: CreateGroupRequest | PlainMessage<CreateGroupRequest> | undefined): boolean {
    return proto3.util.equals(CreateGroupRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.group.CreateGroupResponse
 */
export class CreateGroupResponse extends Message<CreateGroupResponse> {
  /**
   * @generated from field: iam.v1.types.Group group = 1;
   */
  group?: Group;

  constructor(data?: PartialMessage<CreateGroupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.group.CreateGroupResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "group", kind: "message", T: Group },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateGroupResponse {
    return new CreateGroupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateGroupResponse {
    return new CreateGroupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateGroupResponse {
    return new CreateGroupResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateGroupResponse | PlainMessage<CreateGroupResponse> | undefined, b: CreateGroupResponse | PlainMessage<CreateGroupResponse> | undefined): boolean {
    return proto3.util.equals(CreateGroupResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.group.GetGroupRequest
 */
export class GetGroupRequest extends Message<GetGroupRequest> {
  /**
   * @generated from field: string group_dri = 1;
   */
  groupDri = "";

  constructor(data?: PartialMessage<GetGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.group.GetGroupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGroupRequest {
    return new GetGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGroupRequest {
    return new GetGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGroupRequest {
    return new GetGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetGroupRequest | PlainMessage<GetGroupRequest> | undefined, b: GetGroupRequest | PlainMessage<GetGroupRequest> | undefined): boolean {
    return proto3.util.equals(GetGroupRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.group.GetGroupResponse
 */
export class GetGroupResponse extends Message<GetGroupResponse> {
  /**
   * @generated from field: iam.v1.types.Group group = 1;
   */
  group?: Group;

  constructor(data?: PartialMessage<GetGroupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.group.GetGroupResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "group", kind: "message", T: Group },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGroupResponse {
    return new GetGroupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGroupResponse {
    return new GetGroupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGroupResponse {
    return new GetGroupResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetGroupResponse | PlainMessage<GetGroupResponse> | undefined, b: GetGroupResponse | PlainMessage<GetGroupResponse> | undefined): boolean {
    return proto3.util.equals(GetGroupResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.group.ListGroupsRequest
 */
export class ListGroupsRequest extends Message<ListGroupsRequest> {
  /**
   * @generated from field: string group_dri = 1;
   */
  groupDri = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 3;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListGroupsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.group.ListGroupsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListGroupsRequest {
    return new ListGroupsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListGroupsRequest {
    return new ListGroupsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListGroupsRequest {
    return new ListGroupsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListGroupsRequest | PlainMessage<ListGroupsRequest> | undefined, b: ListGroupsRequest | PlainMessage<ListGroupsRequest> | undefined): boolean {
    return proto3.util.equals(ListGroupsRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.group.ListGroupsResponse
 */
export class ListGroupsResponse extends Message<ListGroupsResponse> {
  /**
   * @generated from field: repeated iam.v1.types.Group groups = 1;
   */
  groups: Group[] = [];

  constructor(data?: PartialMessage<ListGroupsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.group.ListGroupsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "groups", kind: "message", T: Group, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListGroupsResponse {
    return new ListGroupsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListGroupsResponse {
    return new ListGroupsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListGroupsResponse {
    return new ListGroupsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListGroupsResponse | PlainMessage<ListGroupsResponse> | undefined, b: ListGroupsResponse | PlainMessage<ListGroupsResponse> | undefined): boolean {
    return proto3.util.equals(ListGroupsResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.group.UpdateGroupRequest
 */
export class UpdateGroupRequest extends Message<UpdateGroupRequest> {
  /**
   * @generated from field: string group_dri = 1;
   */
  groupDri = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  constructor(data?: PartialMessage<UpdateGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.group.UpdateGroupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateGroupRequest {
    return new UpdateGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateGroupRequest {
    return new UpdateGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateGroupRequest {
    return new UpdateGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateGroupRequest | PlainMessage<UpdateGroupRequest> | undefined, b: UpdateGroupRequest | PlainMessage<UpdateGroupRequest> | undefined): boolean {
    return proto3.util.equals(UpdateGroupRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.group.UpdateGroupResponse
 */
export class UpdateGroupResponse extends Message<UpdateGroupResponse> {
  /**
   * @generated from field: iam.v1.types.Group group = 1;
   */
  group?: Group;

  constructor(data?: PartialMessage<UpdateGroupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.group.UpdateGroupResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "group", kind: "message", T: Group },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateGroupResponse {
    return new UpdateGroupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateGroupResponse {
    return new UpdateGroupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateGroupResponse {
    return new UpdateGroupResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateGroupResponse | PlainMessage<UpdateGroupResponse> | undefined, b: UpdateGroupResponse | PlainMessage<UpdateGroupResponse> | undefined): boolean {
    return proto3.util.equals(UpdateGroupResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.group.DeleteGroupRequest
 */
export class DeleteGroupRequest extends Message<DeleteGroupRequest> {
  /**
   * @generated from field: string group_dri = 3;
   */
  groupDri = "";

  constructor(data?: PartialMessage<DeleteGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.group.DeleteGroupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "group_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteGroupRequest {
    return new DeleteGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteGroupRequest {
    return new DeleteGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteGroupRequest {
    return new DeleteGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteGroupRequest | PlainMessage<DeleteGroupRequest> | undefined, b: DeleteGroupRequest | PlainMessage<DeleteGroupRequest> | undefined): boolean {
    return proto3.util.equals(DeleteGroupRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.group.AddPolicyToGroupRequest
 */
export class AddPolicyToGroupRequest extends Message<AddPolicyToGroupRequest> {
  /**
   * @generated from field: string group_dri = 3;
   */
  groupDri = "";

  /**
   * @generated from field: string policy_dri = 4;
   */
  policyDri = "";

  constructor(data?: PartialMessage<AddPolicyToGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.group.AddPolicyToGroupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "group_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "policy_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddPolicyToGroupRequest {
    return new AddPolicyToGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddPolicyToGroupRequest {
    return new AddPolicyToGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddPolicyToGroupRequest {
    return new AddPolicyToGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddPolicyToGroupRequest | PlainMessage<AddPolicyToGroupRequest> | undefined, b: AddPolicyToGroupRequest | PlainMessage<AddPolicyToGroupRequest> | undefined): boolean {
    return proto3.util.equals(AddPolicyToGroupRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.group.RemovePolicyFromGroupRequest
 */
export class RemovePolicyFromGroupRequest extends Message<RemovePolicyFromGroupRequest> {
  /**
   * @generated from field: string group_dri = 1;
   */
  groupDri = "";

  /**
   * @generated from field: string policy_dri = 2;
   */
  policyDri = "";

  constructor(data?: PartialMessage<RemovePolicyFromGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.group.RemovePolicyFromGroupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "policy_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemovePolicyFromGroupRequest {
    return new RemovePolicyFromGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemovePolicyFromGroupRequest {
    return new RemovePolicyFromGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemovePolicyFromGroupRequest {
    return new RemovePolicyFromGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemovePolicyFromGroupRequest | PlainMessage<RemovePolicyFromGroupRequest> | undefined, b: RemovePolicyFromGroupRequest | PlainMessage<RemovePolicyFromGroupRequest> | undefined): boolean {
    return proto3.util.equals(RemovePolicyFromGroupRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.group.AddUserToGroupRequest
 */
export class AddUserToGroupRequest extends Message<AddUserToGroupRequest> {
  /**
   * @generated from field: string group_dri = 1;
   */
  groupDri = "";

  /**
   * @generated from field: string user_dri = 2;
   */
  userDri = "";

  constructor(data?: PartialMessage<AddUserToGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.group.AddUserToGroupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddUserToGroupRequest {
    return new AddUserToGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddUserToGroupRequest {
    return new AddUserToGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddUserToGroupRequest {
    return new AddUserToGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddUserToGroupRequest | PlainMessage<AddUserToGroupRequest> | undefined, b: AddUserToGroupRequest | PlainMessage<AddUserToGroupRequest> | undefined): boolean {
    return proto3.util.equals(AddUserToGroupRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.group.RemoveUserFromGroupRequest
 */
export class RemoveUserFromGroupRequest extends Message<RemoveUserFromGroupRequest> {
  /**
   * @generated from field: string group_dri = 1;
   */
  groupDri = "";

  /**
   * @generated from field: string user_dri = 2;
   */
  userDri = "";

  constructor(data?: PartialMessage<RemoveUserFromGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.group.RemoveUserFromGroupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveUserFromGroupRequest {
    return new RemoveUserFromGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveUserFromGroupRequest {
    return new RemoveUserFromGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveUserFromGroupRequest {
    return new RemoveUserFromGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveUserFromGroupRequest | PlainMessage<RemoveUserFromGroupRequest> | undefined, b: RemoveUserFromGroupRequest | PlainMessage<RemoveUserFromGroupRequest> | undefined): boolean {
    return proto3.util.equals(RemoveUserFromGroupRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.group.ListGroupPoliciesRequest
 */
export class ListGroupPoliciesRequest extends Message<ListGroupPoliciesRequest> {
  /**
   * @generated from field: string group_dri = 1;
   */
  groupDri = "";

  /**
   * @generated from field: string search = 2;
   */
  search = "";

  /**
   * @generated from field: int32 page_size = 3;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 4;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListGroupPoliciesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.group.ListGroupPoliciesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListGroupPoliciesRequest {
    return new ListGroupPoliciesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListGroupPoliciesRequest {
    return new ListGroupPoliciesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListGroupPoliciesRequest {
    return new ListGroupPoliciesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListGroupPoliciesRequest | PlainMessage<ListGroupPoliciesRequest> | undefined, b: ListGroupPoliciesRequest | PlainMessage<ListGroupPoliciesRequest> | undefined): boolean {
    return proto3.util.equals(ListGroupPoliciesRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.group.ListGroupPoliciesResponse
 */
export class ListGroupPoliciesResponse extends Message<ListGroupPoliciesResponse> {
  /**
   * @generated from field: repeated iam.v1.types.Policy policies = 1;
   */
  policies: Policy[] = [];

  constructor(data?: PartialMessage<ListGroupPoliciesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.group.ListGroupPoliciesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policies", kind: "message", T: Policy, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListGroupPoliciesResponse {
    return new ListGroupPoliciesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListGroupPoliciesResponse {
    return new ListGroupPoliciesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListGroupPoliciesResponse {
    return new ListGroupPoliciesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListGroupPoliciesResponse | PlainMessage<ListGroupPoliciesResponse> | undefined, b: ListGroupPoliciesResponse | PlainMessage<ListGroupPoliciesResponse> | undefined): boolean {
    return proto3.util.equals(ListGroupPoliciesResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.group.ListGroupUsersRequest
 */
export class ListGroupUsersRequest extends Message<ListGroupUsersRequest> {
  /**
   * @generated from field: string group_dri = 1;
   */
  groupDri = "";

  /**
   * @generated from field: string search = 2;
   */
  search = "";

  /**
   * @generated from field: int32 page_size = 3;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 4;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListGroupUsersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.group.ListGroupUsersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListGroupUsersRequest {
    return new ListGroupUsersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListGroupUsersRequest {
    return new ListGroupUsersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListGroupUsersRequest {
    return new ListGroupUsersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListGroupUsersRequest | PlainMessage<ListGroupUsersRequest> | undefined, b: ListGroupUsersRequest | PlainMessage<ListGroupUsersRequest> | undefined): boolean {
    return proto3.util.equals(ListGroupUsersRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.group.ListGroupUsersResponse
 */
export class ListGroupUsersResponse extends Message<ListGroupUsersResponse> {
  /**
   * @generated from field: repeated iam.v1.types.User users = 1;
   */
  users: User[] = [];

  constructor(data?: PartialMessage<ListGroupUsersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.group.ListGroupUsersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "users", kind: "message", T: User, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListGroupUsersResponse {
    return new ListGroupUsersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListGroupUsersResponse {
    return new ListGroupUsersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListGroupUsersResponse {
    return new ListGroupUsersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListGroupUsersResponse | PlainMessage<ListGroupUsersResponse> | undefined, b: ListGroupUsersResponse | PlainMessage<ListGroupUsersResponse> | undefined): boolean {
    return proto3.util.equals(ListGroupUsersResponse, a, b);
  }
}

